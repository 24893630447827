<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="9" />
        <div class="top_pic"><img :src="banner" alt=""></div>
        <div class="one_box">
            <div class="content_box">
                <div class="title_nav">
                    <div class="city_text">
                        <img style="width:20px;height:20px" src="../../assets/images/dw.png" alt="">
                        <div style="margin-left:10px;">当前位置：<span @click="choseCitys" style="cursor: pointer;border-bottom:1px solid #fff">{{citys_name}}</span></div>
                    </div>
                  <div style="color:#ffffff">
                    {{ show_title }}
                  </div>
<!--                    <div class="search_box">-->
<!--                        <div class="city_pic">-->
<!--                            <div style="margin-right:5px">中国</div>-->
<!--                            <img style="width:20px;height:20px;" src="../../assets/images/xjt.png" alt="">-->
<!--                        </div>-->
<!--                        <div><input v-model="address" class="inp_style" placeholder="请输入所在地址" type="text"></div>-->
<!--                        <img @click="searchBtn" style="width:20px;height:20px" src="../../assets/images/search.png" alt="">-->
<!--                    </div>-->
                </div>

                <div class="map_box">
                    <div class="left_box">
                        <div v-for="(item,index) in $options.filters.getCitys(addresList)" :key="index" class="item_addres" @click="chioseStore(item.id)">
                            <div class="icon_txt">
                                <img style="width:20px;height:20px" src="../../assets/images/wz.png" alt="">
                                <div style="margin-left:5px">{{item.store_name}}</div>
                            </div>
                            <div class="line_txt">营业时间：{{item.business_time}}</div>
                            <div class="line_txt">地点：{{item.store_address}}</div>
                            <!-- <div class="line_txt">距离：{{item.juli}}</div> -->
                            <div class="icon_txt line_txt">
                                <img style="width:14px;height:14px" src="../../assets/images/phone.png" alt="">
                                <div>{{item.store_tel}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="right_box">
                        <baidu-map id="mapContent" class="map" :center="center" :zoom="zoom" @ready="handler">
                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                              <bm-marker  v-for="(items,indx) in addresList" :key="indx" :position="{lng: items.longitude, lat: items.dimension}" @click="lookDetail(items)" >
                                <bm-info-window  :position="{lng: items.longitude, lat: items.dimension}" :title="items.store_name" :show="items.is_show == '1' "  @close="infoWindowClose(items)" @open="infoWindowOpen(items)">
                                  {{items.store_address}}
                                </bm-info-window>
                              </bm-marker>
                        </baidu-map>
                    </div>
                </div>
            </div>
        </div>
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos" @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    <Tips></Tips>
      <div class="box_citys" v-if="show_citys">
        <div class="frame_box_citys">
          <div @click="closeCitys" class="close_icon_citys"><img src="../../assets/images/close.png" alt=""></div>
          <div class="title_name_citys">选择城市</div>

          <div class="info_box_citys">
            <div @click="choiseCitys('all')" :class="citys_id == 'all' ? 'citys_info choise_city' : 'citys_info'" >全国</div>
            <div  @click="choiseCitys(indx)" :class="citys_id == item.id ? 'citys_info choise_city' : 'citys_info'" v-for="(item, indx) in citys" :key="indx">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  var _this;
    import {storeindex,cateBanner,get_lng} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
import Tips from "@/components/tips.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame,
    Tips
        },
        data() {
            return {
                center: {lng: 116.404,lat: 39.915},
                zoom: 14,
                address:'',
                addresList:[],
                banner:'',
                JoinModal:false,
                citys:[],
                citys_name:'广州',
                citys_id:'0',
                show_citys:false,
                show_title:"10座城市，88店"
            }
        },

      beforeCreate: function () {
        _this = this
      },
        mounted() {
            this.cateBanner()
            this.storeindex()
        },
        filters:{
            getCitys(e){
              if (_this.citys_id == 'all') {
                return e;
              }
              return e.filter(function(item){
                 return item.citys_id == _this.citys_id
              })
            }
        },
        methods:{
          // 点击点坐标赋值
          lookDetail(v){
            console.log(1)
            v.is_show = '1'
            // this.infoWindow.info = marker;
          },
// 关闭弹窗
          infoWindowClose(v) {
            v.is_show = false
          },
// 打开弹窗
          infoWindowOpen(v) {
            v.is_show = '1'
          },
            choseCitys(){
              this.show_citys = true;
            },

            closeCitys(){
              this.show_citys = false;
            },
            choiseCitys(e){
              if (e == "all") {
                this.citys_name = "全国"
                this.citys_id = "all"
                this.setMapCenter(0, 9);
              } else {
                this.citys_name = this.citys[e].name
                this.citys_id = this.citys[e].id
                let j = 0;
                for (let i in this.addresList) {
                  if (this.addresList[i].citys_id == this.citys_id && j == 0){
                     j = 1;
                    this.setMapCenter( i, 16);
                  }
                }
              }
              this.show_citys = false;

            },
           chioseStore(e){
              for (let i in this.addresList) {
                  if (this.addresList[i].id == e){
                    this.setMapCenter(i, 16);
                  }
              }

           },
          setMapCenter(i, leavel){
            this.addresList[i].is_show = 1
            let lats = parseFloat(this.addresList[i].longitude);
            let lngs = parseFloat(this.addresList[i].dimension);
            this.map.setCenter(new BMap.Point(lats,lngs));
            // this.map.setZoom(leavel);

          },
            cateBanner(){
                cateBanner({id:9}).then(res=>{
                    this.banner = res.data
                })
            },
            storeindex(){
                storeindex().then(res=>{
                  this.addresList = res.data.list
                  this.show_title = res.data.title
                  this.citys = res.data.citys
                  this.citys_name = this.citys[0].name
                  this.citys_id = this.citys[0].id
                })
            },
            searchBtn(){
                get_lng({address:this.address}).then(res=>{
                    this.center.lng = res.data.result.location.lng
                    this.center.lat = res.data.result.location.lat
                }).catch(err=>{
                    this.$Message.warning('暂无门店')
                })
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
            handler({ BMap, map }){
                this.BMap = BMap
                this.map = map
                setTimeout(() => {
                  console.log(this.addresList[0])
                    this.center.lng = parseFloat(this.addresList[0].longitude)
                    this.center.lat = parseFloat(this.addresList[0].dimension)
                    map.addOverlay(new this.BMap.Marker(this.center))
                }, 100);
            }
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.one_box{
    width: 80%;
    margin: 0 auto;
}
.content_box{
    width: 100%;
    margin: 30px 0;
    border-radius: 12px;
    border: 1px solid #eee;
    overflow: hidden;
}
.title_nav{
    width: 100%;
    background: #A91800;
    padding: 10px 30px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.city_text{
    display: flex;
    align-items: center;
    color: #FFF;
}
.search_box{
    background: #FFF;
    display: flex;
    padding: 10px 10px;
    align-items: center;
    border-radius: 5px;
}
.city_pic{
    display: flex;
    align-items: center;
    color: #A91800;
    margin-right: 5px;
}
.map_box{
    width: 100%;
    display: flex;
}
.left_box{
    width: 30%;
    padding: 10px;
    box-sizing: border-box;
    height: 500px;
    overflow: auto;
}
.right_box{
    width: 70%;
    height: 500px;
}
.item_addres{
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  cursor: pointer;
}
.icon_txt{
    display: flex;
    align-items: center;
}
.line_txt{
    color: #999;
    font-size: 12px;
    margin-left: 25px;
}


.inp_style{
    outline: none;
    border: none;
}
.map {
  width: 100%;
  height: 100%;
}
.anchorBL{
  display: none;
}
.box_citys{
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame_box_citys{
  position: relative;
  width: 700px;
  background: #FFF;
  border-radius: 10px;
}
.title_name_citys{
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0;
  background: #A91800;
  color:#ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.close_icon_citys{
  position: absolute;
  top: 15px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.close_icon_citys img{
  width: 100%;
  height: 100%;
}

.info_box_citys{
  display: flex;
  align-items: center;
  padding: 30px;
  flex-flow: wrap;
  width:100%;
}
.citys_info{
  margin:10px 15px;
  font-size:18px;
  cursor: pointer;
}
.choise_city{
  border-bottom:1px solid #000000;
}
</style>